import { Row, Col, Affix, Radio } from 'antd';
import { PureComponent } from 'react';
import { SearchOutlined, SmileOutlined } from '@ant-design/icons';
import { Input, Button } from 'antd';
import React from 'react';

import "./index.less"
import { DeviceInfoContext } from '@/pages';
import { getNavBar } from '@/function/getNavBar';


type NewsSearchBarProps = {
  size?: "small" | "default"
  paddingPortrait?: [pc: number, mobile: number, half?: number]
  defaultValue?: string
  onSearch?: (searchText: string) => void
}
type NewsSearchBarState = {
  inputText?: string
}
export class NewsSearchBar extends PureComponent<NewsSearchBarProps, NewsSearchBarState> {
  constructor(props: NewsSearchBarProps) {
    super(props)
    this.state = {
      inputText: this.props.defaultValue
    }
  }
  placeholder: string = "搜索资讯关键词";
  render() {
    console.log(" this.props.defaultValue", this.props.defaultValue, this.state.inputText)
    const SearchIconStyle: React.CSSProperties = {
      fontSize: 32,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
    const commonClass = ["search"]
    if (this.props.size === "small") { commonClass.push("small") }
    return <div className="NewsSearchBar" style={{ textAlign: "left", width: "100%" }}>
      <DeviceInfoContext.Consumer>
        {(context) => {
          return <Row className='NewsSearchBar'>
            {/* pc */}
            <Col span={0} sm={0} xl={24}>
              <div>
                <Input.Search
                  value={this.state.inputText}
                  className={[...commonClass].join(" ")}
                  placeholder={this.placeholder}
                  onChange={this.onChange.bind(this)}
                  onSearch={this.onSearch.bind(this)}
                  suffix={<Button type="link" onClick={this.onSearch.bind(this)}>
                    <SearchOutlined style={{ ...SearchIconStyle }} />
                  </Button>}
                  style={{
                    transition: "padding 1s",
                    width: 505,
                    paddingTop: this.props.paddingPortrait?.[0] || 30,
                    paddingBottom: this.props.paddingPortrait?.[0] || 30,
                    paddingLeft: 30,
                    paddingRight: 30,
                  }} />
              </div>
            </Col>
            {/* tab */}

            <Col span={0} sm={24} xl={0}>
              <div style={{ width: "100%" }}>
                <Input.Search
                  value={this.state.inputText}
                  className={[...commonClass].join(" ")}
                  placeholder={this.placeholder}
                  onChange={this.onChange.bind(this)}
                  onSearch={this.onSearch.bind(this)}
                  suffix={<Button type="link" onClick={this.onSearch.bind(this)}>
                    <SearchOutlined style={{ ...SearchIconStyle }} />
                  </Button>}
                  style={{
                    transition: "padding 1s",
                    maxWidth: 505,
                    width: "100%",
                    paddingTop: this.props.paddingPortrait?.[0] || 30,
                    paddingBottom: this.props.paddingPortrait?.[0] || 30,
                    paddingLeft: 30,
                    paddingRight: 30,
                  }} />
              </div>
            </Col>
            {/* mobile */}
            <Col span={24} sm={0} xl={0}>
              <div>
                <Input.Search
                  value={this.state.inputText}
                  className={[...commonClass, "mobile"].join(" ")}
                  placeholder={this.placeholder}
                  onChange={this.onChange.bind(this)}
                  onSearch={this.onSearch.bind(this)}
                  suffix={<Button type="link" onClick={this.onSearch.bind(this)}>
                    <SearchOutlined style={{ ...SearchIconStyle }} />
                  </Button>}
                  style={{
                    transition: "padding-left 1s,padding-right 1s,padding 1s,",
                    width: "100%",
                    paddingTop: 20,
                    paddingLeft: this.props.paddingPortrait?.[1] || 26,
                    paddingRight: this.props.paddingPortrait?.[1] || 26,
                  }} />
              </div>
            </Col>
          </Row>
        }}
      </DeviceInfoContext.Consumer>

    </div>
  }
  onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const text = event.target.value
    this.setState({
      inputText: text
    })
  }
  onSearch() {
    if (this.props.onSearch) {
      this.props.onSearch(this.state.inputText || "")
    }
  }

}

