import { PureComponent } from 'react';



type BackgroundProps = {
    children: React.ReactNode
}

export class Background extends PureComponent<BackgroundProps> {
    constructor(props: BackgroundProps) {
        super(props)

    }
    render() {

        return <div className="Background" style={{
            width: "100%",
            background: `#F5F6F8`,
        }}>
            <div className="Background-bg" style={{ width: "100%", height: 0, }}>
                <div className="Background-bg-bg" style={{ width: "100%", height: 800, background: `linear-gradient(#fff, #F5F6F8)`, }} />

            </div>
            {this.props.children}
        </div>
    }
}

