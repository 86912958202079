/**获取navbar 
 * 
 * 自动识别手机或电脑 */
export const getNavBar = () => {
    const elements = document.getElementsByTagName("nav")
    for (let element of elements) {
        const style = getComputedStyle(element)
        if (style.display !== "none") {
            return {
                element,
                height:element.offsetHeight
            }
        }
    }
}