import { Col, Input, List, Row, Space, Typography } from 'antd';
import React, { PureComponent } from 'react';

import {
  EyeOutlined, LoadingOutlined
} from '@ant-design/icons';


import { toURL } from '@/function/url';
import { HotIcon } from '@/switch/res/Icon/Hot';
import { ResponseData, Sort } from '../../PageMain';
import "./index.less";
const { Search } = Input;

export type News = {
  key: string,
  image: URL,
  title: string
  description: string
  createTime: Date
  views: number
  isHot: boolean
  link: URL
}

type NewsListProps = {
  sort: Sort
  firstData: ResponseData["news_list"]
}
type NewsListState = {
  listData: ResponseData["news_list"]
  nextPage: number
  hasNextPage?: boolean
  fetchingData?: boolean
}
export class NewsList extends PureComponent<NewsListProps, NewsListState> {
  constructor(props: NewsListProps) {
    super(props)
    this.state = {
      listData: this.props.firstData,
      nextPage: 1,
      hasNextPage: true,
    }
  }
  render() {
    console.log("NewsList",)
    const newsData: News[] = Array.from(this.state.listData).map((item, key) => ({
      key: String(key),
      image: new toURL(item.cover_img),
      title: item.title,
      description: item.summary,
      createTime: new Date(item.time),
      views: Number(item.view),
      isHot: item.sticky === "1",
      // link: item.url,
      link: new toURL(item.url),
    }));

    return <div className="NewsList" style={{ textAlign: "left", width: "100%", padding: 0, paddingTop: 0 }}>
      <List
        itemLayout="vertical"
        size="large"
        locale={{ emptyText: "空" }}
        dataSource={newsData}
        footer={<InfiniteLoading onTriggered={this.onInfiniteLoadTriggered.bind(this)} disabled={!this.state.hasNextPage} />}
        renderItem={(item, key) => {
          const showFire = item.isHot
          const Views = <div>
            <EyeOutlined style={{ marginRight: 8, display: "inline-flex", height: "100%", verticalAlign: "top", alignItems: "center" }} />
            <div style={{ display: "inline-block", height: "100%" }}>
              {item.views}
            </div>
          </div>
          const titleStyle: React.CSSProperties = { paddingRight: 32 }
          return <Row
            style={{ cursor: "pointer" }}
            onClick={() => { document.location.href = item.link.href }}>
            {/* mobile */}
            <Col span={24} md={0} xl={0}>
              <List.Item
                style={{ padding: 24, }}
                key={item.key}
                extra={
                  <List.Item.Meta
                    style={{ marginTop: 16 }}
                    description={<div style={{ position: "relative" }}>
                      <div style={{ position: "absolute", right: (16 - 24), top: -19, display: showFire ? undefined : "none" }}>
                        <HotIcon />
                      </div>
                      <Space direction="vertical" size={14} style={{ width: "100%" }}>
                        <a href={item.link.href} style={{ fontSize: 24 }}><h4 style={{ ...titleStyle }}>{item.title}</h4></a>
                        <div style={{
                          backgroundImage: `url(${item.image.href})`,
                          width: "100%",
                          height: 150,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: 5,
                          overflow: "hidden",
                        }} />
                        <Typography.Paragraph ellipsis={{ rows: 4, expandable: false, }} style={{ margin: 0, width: "100%" }}>
                          {item.description}
                        </Typography.Paragraph>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div>{item.createTime.toLocaleString()}</div>
                          {Views}
                        </div>
                      </Space>
                    </div>}
                  />
                }
              />
            </Col>
            {/* pc板: */}
            <Col span={0} md={24} xl={24} >
              <List.Item
                style={{ padding: 40, paddingTop: 0, paddingBottom: 0 }}
                key={item.key}

                extra={
                  <List.Item.Meta
                    style={{ marginTop: 16 }}
                    description={
                      <div>
                        <div style={{ position: "absolute", right: 46, top: 16 + 4, display: showFire ? undefined : "none" }}>
                          <HotIcon />
                        </div>
                        <Space direction="vertical" size={14} style={{ width: "100%" }}>
                          <a href={item.link.href} style={{ fontSize: 24, maxWidth: "calc(100% - 32px)" }}><h4 style={{ ...titleStyle }}>{item.title}</h4></a>
                          <Typography.Paragraph ellipsis={{ rows: 3, expandable: false, }} style={{
                            margin: 0, width: "100%",
                            lineHeight: "22px",
                            minHeight: `calc(22px * 3)`
                          }}>
                            {item.description}
                          </Typography.Paragraph>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>{item.createTime.toLocaleString()}</div>
                            {Views}
                          </div>
                        </Space>
                      </div>
                    }
                  />
                }
              >
                <div style={{
                  backgroundImage: `url(${item.image.href})`,
                  width: 219,
                  height: 124,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: 5,
                  overflow: "hidden",
                }} />
              </List.Item>
            </Col>
          </Row>
        }}
      />
    </div>
  }
  componentDidMount() {

  }
  onInfiniteLoadTriggered() {
    if (!this.state.fetchingData && this.state.hasNextPage) {
      this.fillNextPage()
    }
  }
  async fillNextPage() {


    interface SearchInterface {
      code: number;
      message: string;
      data: ResponseData["news_list"];
    }


    this.setState({ fetchingData: true })
    const pageId = this.state.nextPage
    const url = new URL(document.location.href)
    url.searchParams.set("page", String(pageId))

    fetch(url, { headers: { "X-Requested-With": "XMLHttpRequest" } }).then(async (data) => {
      return await data.json() as SearchInterface
    }).then((json) => {
      console.log("data2", json)
      const items = json.data
      if (items.length === 0) {
        this.setState({ hasNextPage: false })
      }
      const listData = [...this.state.listData, ...items]

      this.setState({
        listData,
        nextPage: (pageId + 1)
      })
    }).catch(() => {
      console.error("fetchfailed")
      this.setState({ hasNextPage: false })
    }).then(() => {
      this.setState({ fetchingData: false })
    })

  }
}


type InfiniteLoadingProps = {
  disabled?: boolean
  onTriggered?: () => void
}

type InfiniteLoadingState = {
  previousVisibleValue?: boolean
}

export class InfiniteLoading extends PureComponent<InfiniteLoadingProps, InfiniteLoadingState> {
  ref: React.RefObject<HTMLDivElement>;
  constructor(config: InfiniteLoadingProps) {
    super(config)
    this.ref = React.createRef()
    this.state = {

    }
  }
  render() {
    return <div ref={this.ref} className={"InfiniteLoading"} style={{ textAlign: "center" }}>
      <LoadingOutlined style={{ fontSize: 36, display: this.props.disabled ? "none" : undefined }} />
    </div>
  }
  componentDidMount() {
    const ref = this.ref.current
    if (ref) {
      let observer = new IntersectionObserver(this.callback.bind(this), { threshold: 1.0 });
      observer.observe(ref);
    }

  }
  callback(entry: IntersectionObserverEntry[]) {
    const first = entry.shift()
    if (first) {
      if ("isIntersecting" in first) {
        const isVisible = first.isIntersecting

        if (!this.state.previousVisibleValue && isVisible) {
          if (this.props.onTriggered) {
            this.props.onTriggered()
          }
        }

        this.setState({ previousVisibleValue: isVisible })
      }
    }
  }
}

