import { toURL } from '@/function/url';
import React, { PureComponent } from 'react';
import { Jsonable } from 'ts-extend';
import { Page_HomePage } from './pages/HomePage';
import { Page_NewsList } from './pages/newsList/PageMain';
import { Page_NewsDetail as Page_NewsDetail } from './pages/NewsPage';
// import { Page_NewsList } from './pages/newsList/PageMain/Main_Main';

type SwitchState = {
    ready: boolean
    //bu shi zhi dao sha lei x
    displays?: any
    message?: string
}

export class Switch extends PureComponent<{}, SwitchState> {
    constructor(input: {}) {
        super(input)
        this.state = {
            ready: false
        }
    }

    render() {
        if (this.state.ready) {
            return <div className="swtich-container">
                {this.state.displays || this.state.message || "ERROR!"}
            </div>
        }

        return <div className="swtich-container holding">
            holding
        </div>
    }
    async componentDidMount() {
        const tag = this.getInputTag()
        const data = this.getData() as any
        let displays
        if (tag) {
            console.warn("tag", tag)
            switch (tag.tag) {
                case "news-list":
                    displays = <Page_NewsList data={data} />
                    break;
                case "news-page":
                    displays = <Page_NewsDetail data={data} />
                    break;
                case "home-page":
                    displays = <Page_HomePage data={data} />
                    break;

                default:
                    break;
            }
        }
        this.setState({
            ready: true,
            displays
        })
    }

    getInputTag() {
        const rootData = this.getRoot()
        let tag = rootData?.pagetag
        let debug = false
        if (!tag) {
            tag = new toURL(document.location.href).searchParams.get("react_force_tag") || undefined
            debug = true
        }
        return { tag, debug }
    }

    getRoot() {
        const root = document.getElementById("react-json-data-source") || undefined
        if (root) {
            try {

                return {
                    root,
                    pagetag: root.getAttribute("pagetag") || undefined,
                }
            } catch (error) {

            }

        }
        return
    }
    getData() {
        const root = this.getRoot()
        if (root?.root) {
            // const text = root.root.innerText
            const text = root.root.getAttribute("react") || ""
            try {
                const data: Jsonable = JSON.parse(text)
                return data
            } catch (error) {
                console.error(error, text)
            }

        }
        return
    }
}

