import React from "react";
import { MixLoader } from "react-entrance";
import ReactDOM from "react-dom";
// import {client} from "react-dom";
import Page from "./pages/index"
import { ReactWarn2Warn } from 'fuck-react-warning';



ReactWarn2Warn()


new MixLoader({
    rootId: "react-json-data-source",
    rootNode: <Page />
})
