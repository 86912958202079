import { DeviceInfoContext } from '@/pages';
import { Input } from 'antd';
import { PureComponent } from 'react';

import "./index.less"

const { Search } = Input;



type LetterPaperProps = {
    children: React.ReactNode
}
type LetterPaperState = {

}

export class LetterPaper extends PureComponent<LetterPaperProps, LetterPaperState> {
    constructor(props: LetterPaperProps) {
        super(props)
        this.state = {

        }
    }
    render() {
        return <DeviceInfoContext.Consumer>
            {(context) => {
                const className = ["LetterPaper"]
                switch (context.type) {
                    case "mobile":
                        className.push("LetterPaper-mobile")
                        break;
                    case "half":
                        className.push("LetterPaper-half")
                        break;
                    case "fullWidth":
                        className.push("LetterPaper-fullWidth")
                        break;

                    default:
                        break;
                }

                return <div className={className.join(" ")} style={{ width: "100%", }}>
                    <div style={{


                    }}>
                        {this.props.children}
                    </div>
                </div>
            }}
        </DeviceInfoContext.Consumer>
    }
}

