import { FontsCSS } from "./assets/fonts"




export const DynamicAssets = (type: "style" | "js", content: string, pos: "head" | "foot" = "head") => {
    let element: any = document.createElement('div')
    switch (type) {
        case "style":
            {
                element = document.createElement('style');
                element.innerHTML = content
            }
            break;
        case "js":
            {
                element = document.createElement('script');
                element.innerHTML = content
            }
            break;

        default:
            break;
    }

    switch (pos) {
        case "head":
            document.head.appendChild(element);
            break;
        case "foot":
            document.body.appendChild(element);
            break;

        default:
            break;
    }


}

const ASSETS: {
    [key in "fonts"]: {
        content: string
        type: Parameters<typeof DynamicAssets>[0]
    }
} = {
    "fonts": {
        content: FontsCSS,
        type: "style"
    }
}


export const setAsset = (key: keyof typeof ASSETS) => {
    const data = ASSETS[key]
    DynamicAssets(data.type, data.content)
}
