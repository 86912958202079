import { toURL } from '@/function/url';
import { DeviceInfoContext } from '@/pages';
import { Carousel } from 'antd';
import React, { PureComponent } from 'react';

interface CarouselImage {
  image: string;
  title: string;
  image_title: string;
  image_alt: string;
  link: string | null;
}


type ReactData = CarouselImage[]



type HomePageProps = {
  data: ReactData
}
type HomePageState = {
  data: ReactData
  autoplay: boolean
}

export class Page_HomePage extends PureComponent<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props)

    // let defaultData = JSON.parse('[ { "image": "/sites/default/files/2022-08/62f0821738ca5.png", "title": "共赢" }, { "image": "/sites/default/files/2022-08/62f08c9ca9bd5.png", "title": "创新" }, { "image": "/sites/default/files/2022-08/62f08c9ca9bd5.png", "title": "创新" }, { "image": "/sites/default/files/2022-08/62f08c9ca9bd5.png", "title": "创新" }, { "image": "/sites/default/files/2022-08/62f08c9ca9bd5.png", "title": "创新" } ]')
    let defaultData: CarouselImage[] = [
      {
        "image": "/sites/default/files/2022-08/62f0a04562e9d.png",
        "title": "共赢",
        "link": null,
        "image_title": "",
        "image_alt": ""
      },
      {
        "image": "/sites/default/files/2022-08/62f0b4083cf77.png",
        "title": "创新",
        "link": null,
        "image_title": "",
        "image_alt": ""
      },
      {
        "image": "/sites/default/files/2022-08/62f0b45b8c693.png",
        "title": "自主",
        "link": null,
        "image_title": "",
        "image_alt": ""
      },
      {
        "image": "/sites/default/files/2022-08/62f0b4853e812.png",
        "title": "可信",
        "link": null,
        "image_title": "",
        "image_alt": ""
      },
      {
        "image": "/sites/default/files/2022-08/62f0b4dc48bc0.png",
        "title": "开放",
        "link": null,
        "image_title": "",
        "image_alt": ""
      },
      {
        "image": "/sites/default/files/2022-08/62f0b5178dc3f.png",
        "title": "开源",
        "link": "https://dev.openoga.com/news/123",
        "image_title": "2",
        "image_alt": "1"
      }
    ]


    this.state = {
      data: this.props.data || defaultData,
      autoplay: false
    }
  }
  render() {
    const className = ["HomePage"]
    return <div className={className.join(" ")} style={{ textAlign: "center", width: "100%", backgroundColor: "black" }}>
      <Carousel autoplay={this.state.autoplay}>
        {Array.from(this.state.data, (item, id) => {
          const first = id === 0
          const url = new toURL(item.image)
          return <div style={{ contentVisibility: "auto", }}>
            <a href={item.link || ""}>
              <img alt={item.image_alt} title={item.image_title} src={url.href}
                style={{ width: "100%", contentVisibility: "auto", }}
                loading={"lazy"}
                onLoad={first ? this.onFirstPageLoaded.bind(this) : undefined}
              />
            </a>
          </div>
        })}
      </Carousel>
    </div>
  }
  onFirstPageLoaded() {
    this.setState({
      autoplay: true
    })
  }
}
