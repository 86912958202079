import { Col, Row } from 'antd';
import React, { PureComponent } from 'react';


import {
  EyeOutlined, LoadingOutlined
} from '@ant-design/icons';

import { DeviceDetector } from '@/function/DeviceDetector';
import { DeviceInfoContext } from '@/pages';
import { HotIcon } from '@/switch/res/Icon/Hot';
import "./index.less";




type NewsPageProps = {
  isHot: boolean
  title: string
  views: number
  created: Date
  paddingLeftnRight?: number
}
type NewsPageState = {

}

export class NewsDetail_TitleBar extends PureComponent<NewsPageProps, NewsPageState, DeviceDetector> {
  constructor(props: NewsPageProps) {
    super(props)
    this.state = {

    }
  }
  render() {
    const Views = <div>
      <EyeOutlined style={{ marginRight: 8, display: "inline-flex", height: "100%", verticalAlign: "revert", alignItems: "center" }} />
      <div style={{ display: "inline-block", height: "100%" }}>
        {this.props.views || 888}
      </div>
    </div>
    return <DeviceInfoContext.Consumer>
      {(context) => {
        const baseContent: React.CSSProperties = {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // marginBottom: 32,
          paddingLeft: this.props.paddingLeftnRight,
          paddingRight: this.props.paddingLeftnRight,
          paddingTop: 40,
        }
        const h1BaseStyle: React.CSSProperties = {
          width: "100%",
          fontWeight: '500',
          letterSpacing: -1,
          fontSize: '2.7rem',
          textAlign: 'left',
          margin: '0',
          // whiteSpace: 'nowrap',
          // textOverflow: 'ellipsis',
          // overflow: 'hidden',
          maxWidth: 675,
          marginRight: 20,
        }
        const timeBaseStyle: React.CSSProperties = {
          color: "#9A9A9A", minWidth: 120
        }

        const showHotIcon = this.props.isHot
        return <Row className='TitleBar' style={{ position: "relative" }}>
          <Col span={0} lg={0} xl={24}>
            <div className={"TitleBar-content-full"} style={{ ...baseContent, }}>
              <h1 className='Font-Sans' style={{ ...h1BaseStyle }}>{this.props.title}</h1>
              <HotIcon style={{ width: 20, display: showHotIcon ? undefined : "none" }} />
              <div className='time' style={{ ...timeBaseStyle, paddingLeft: 50, whiteSpace: "nowrap" }}>
                {Views}
                {this.props.created.toLocaleString()}
              </div>
            </div>
          </Col>
          <Col span={0} lg={24} xl={0} >
            <div className={"TitleBar-content-half"} style={{ ...baseContent }}>
              <h1 className='Font-Sans' style={{ ...h1BaseStyle }}>{this.props.title}</h1>
              <HotIcon style={{ width: 20, display: showHotIcon ? undefined : "none" }} />
              <div className='time' style={{ ...timeBaseStyle, paddingLeft: 10, whiteSpace: "nowrap" }}>
                {Views}
                {this.props.created.toLocaleString()}
              </div>
            </div>
          </Col>
          <Col span={24} lg={0} xl={0} className={"TitleBar-content-mobile-Col"} style={{
            position: "relative",
            paddingTop: 32,
            paddingLeft: this.props.paddingLeftnRight,
            paddingRight: this.props.paddingLeftnRight,
          }} >
            {/* <HotIcon style={{ position: "absolute", top: 13, left: 22, width: 13, display: showHotIcon ? undefined : "none" }} /> */}
            <HotIcon style={{ position: "absolute", top: 13, left: this.props.paddingLeftnRight, width: 13, display: showHotIcon ? undefined : "none" }} />
            <div className={"TitleBar-content-mobile"} style={{ ...h1BaseStyle, display: "block", marginBottom: 16 }}>
              <h1 className='Font-Sans' style={{ width: "100%", whiteSpace: "normal", fontSize: 22 }}>{this.props.title}</h1>
              <div className='time' style={{ display: "block", textAlign: "right", fontSize: 16, whiteSpace: "nowrap" }}>
                {Views}
                {this.props.created.toLocaleString()}
              </div>
            </div>
          </Col>
        </Row>
      }}
    </DeviceInfoContext.Consumer>

  }

}

