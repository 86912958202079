import { Row, Col, Dropdown, Radio } from 'antd';
import { PureComponent } from 'react';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Menu, Space } from 'antd';
import React from 'react';

import "./index.less"



type SwitchOption<KeyType extends string> = {
  displays: string
  key: KeyType
}

type NewsSwitchProps<KeyType extends string> = {
  options: SwitchOption<KeyType>[]
  dropDownText?: string
  default?: KeyType
  onChange?: (selectedKey: KeyType) => void
}
type NewsSwitchState<KeyType extends string> = {
  selected?: KeyType
}
export class NewsSwitch<KeyType extends string = string> extends PureComponent<NewsSwitchProps<KeyType>, NewsSwitchState<KeyType>> {
  constructor(props: NewsSwitchProps<KeyType>) {
    super(props)
    this.state = {
      selected:this.props.default
    }
  }
  render() {
    return <div className="NewsSwitch" style={{ textAlign: "left", width: "100%", height: "100%", }}>
      <Row style={{ display: "flex", alignItems: "center", height: "100%",fontSize:14 }}>
        {/* tablet */}
        {/* <Col span={0} sm={24} xl={0}>
          <div>
            <Radio.Group
              className={"miniRadio"}
              defaultValue="time"
              buttonStyle="solid"
              value={this.state.selected}
              onChange={(event) => { this.switchTo(event.target.value) }}
            >
              {Array.from(this.props.options, (item, id) => {
                return <Radio.Button value={item.key}>{item.displays}</Radio.Button>
              })}
            </Radio.Group>
          </div>
        </Col> */}
         {/* pc */}
        <Col span={0} sm={24} xl={24} >
          <div>
            <Radio.Group
              defaultValue="time"
              buttonStyle="solid"
              value={this.state.selected}
              onChange={(event) => { this.switchTo(event.target.value) }}
            >
              {Array.from(this.props.options, (item, id) => {
                return <Radio.Button value={item.key}>{item.displays}</Radio.Button>
              })}
            </Radio.Group>
          </div>
        </Col>
        <Col span={24} sm={0} >
          <div style={{ justifyContent: "flex-end", marginRight: 26, marginLeft: 26 }}>
            <Dropdown overlay={<Menu items={this.toMenuData(this.props.options)} />}>
              <a onClick={e => e.preventDefault()}>
                <Space>
                  {this.props.dropDownText || "dropDownText"}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Col>
      </Row>



    </div>
  }
  switchTo(nextKey: KeyType) {
    this.setState({
      selected: nextKey
    })
    if (this.props.onChange) {
      this.props.onChange(nextKey)

    }
  }
  toMenuData(input: SwitchOption<KeyType>[]) {
    const data = Array.from(input, (item) => {
      return {
        key: item.key,
        label: (
          <div onClick={() => { this.switchTo(item.key) }}>
            {item.displays}
          </div>
        ),
      }
    });

    return data



  }

}

