import { baseUrl } from "@/config/config"


export class toURL extends URL {
    constructor(url: string) {
        console.log("url,1",url)

        if(typeof url !== "string"){
            console.error("无法识别的URL",url)
            url = "/"
        }
        if (url.startsWith("/")) {
            if(baseUrl && localStorage && localStorage.dev === "true"){
                url = `${baseUrl.origin}${url}`
            }else{
                url = `${document.location.origin}${url}`
            }
        }
        console.log("url,",url)
        try {
            new URL(url)
            super(url)
        } catch (error) {
            console.error("URL 转换失败")
            super(document.location.origin)
        }
    }
}