import React from "react"

export const HotIcon = (props?: { style?: React.CSSProperties }) => {
    return <svg
        width="20"
        height="24"
        style={props?.style}
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M19.7502 13.7225C19.7502 12.4488 19.0654 9.63299 17.5785 6.78644C17.552 7.73785 16.7915 10.0435 16.2315 10.6573C15.8569 9.58312 15.7623 2.41304 6.43585 0C7.00339 7.13171 0 7.02813 0 14.7238C0 18.8133 2.4177 22.3312 5.87966 23.8926C5.0889 19.1739 9.68593 18.5678 8.94435 13.7072C13.7873 16.6458 14.9262 20.0831 13.6171 24C17.4839 22.3581 20.0605 18.2494 19.7502 13.7225Z" fill="#FF6060" />
    </svg>

}