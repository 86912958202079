import { Switch } from '@/switch';
import React, { PureComponent } from 'react';
import 'antd/dist/antd.min.css';
import "./global.less";
import "./index.less";
import { setAsset } from '@/DynamicAssets';
import { DeviceDetector } from '@/function/DeviceDetector';
import { MixLoader } from 'react-entrance';



const Device = new DeviceDetector()
export const DeviceInfoContext = React.createContext(Device.info);

type TopState = {
  device: typeof Device.info
}

class Page extends PureComponent<{}, TopState> {
  constructor({ }) {
    super({})
    this.state = {
      device: Device.info
    }
  }

  isSafari() {
    return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
  }

  render() {
    return <DeviceInfoContext.Provider value={this.state.device}>
      <div className="entrance">
        <Switch />
      </div>
    </DeviceInfoContext.Provider>
  }
  componentDidMount() {
    setAsset("fonts")

    Device.onChange = (info) => {
      this.setState({
        device: info
      })
    }

  }
  getDeviceInfo() {
    return new DeviceDetector()
  }
}

export default Page
