export const FontsCSS = `
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@200;300;400;500;600;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap');

.Font-Sans{
  font-family: 'Noto Sans SC', sans-serif;
}

body{
  font-family: 'Noto Serif SC', serif !important;
}
`