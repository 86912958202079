type DeviceType = "fullWidth" | "half" | "mobile"
export type DeviceInfo = {
    type: DeviceType
}


export class DeviceDetector {
    public readonly info: DeviceInfo
    constructor() {
        this.info = this.getInfo()
        window.addEventListener("resize", () => {
            const info = this.getInfo()
            this.onChange(info)
        });

    }
    private getInfo(): DeviceInfo {
        let type: DeviceType = "fullWidth"
        const vWidth = document.body.offsetWidth
        if (vWidth >= 1200) {
            type = "fullWidth"
        } else if (vWidth >= 576) {
            type = "half"
        } else {
            type = "mobile"
        }
        ////

        const info: DeviceInfo = {
            type
        }
        return info
    }
    public onChange(info: DeviceInfo) { }
    
}